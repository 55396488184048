@import "../../../global.scss";
.my_table{
    width: 100%;
    height: auto;
    max-height: calc(100% - 5rem);
    background-color: rgba(255, 255, 255, 0);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    @include mobile_potrait{
        max-height: calc(100% - 3rem);
    }
    @include mobile_landscape{
        padding: 0.5rem;
    }
    .noData{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        span{
            font-size: 2rem;
            text-align: center;
            color: #000;
            text-transform: uppercase;
            margin-top: 3rem;
            margin-bottom: 2rem;
            @include mobile_potrait{
                font-size: 1.5rem;
                text-align: center;
            }
            @include mobile_landscape{
                font-size: 1.5rem;
                margin-top: 0rem !important;
                margin-bottom: 0.5rem!important;
            }
        }
    }
    .MuiPaper-elevation1{
        margin-bottom: 0rem;

    }
    .MuiPaper-root{
        background-color: #ffffff00;
    }
}
th {
    min-width: 2rem !important;
    padding: 0.2rem !important;
    border-color: #ffffff11 !important;
    span{
        color: #fff !important;
        svg{
            display: none !important; 
            fill: #999999 !important;
        }
    }
}
tbody{
    
    th {
        min-width: 2rem !important;
        padding: 8px 0px !important;
        border-color: #ffffff11 !important;
    }
    td {
        min-width: 2rem !important;

        padding: 0.2rem !important;
        
    }
}

.my_cell{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    margin-right: 0rem;
    color: #fff;
    @include mobile_potrait{
        font-size: 12px;
    }
}
.MuiTablePagination-root{
    width: 100%;
    display: flex;
    align-items: center;
    .MuiToolbar-root{
        padding-left: 0;
    }
    .MuiToolbar-gutters{
       
        p{
            font-size: 12px;
            margin-bottom: 0rem;
            color: #777;
            width: auto;
            @include mobile_potrait{
                // display: none;
            }
            @include mobile_landscape{
                font-size: 0.8rem;
            }
        }
        .MuiTablePagination-selectLabel{
            @include mobile_potrait{
                display: none;
            }
        }
        div{
            margin-left: 0rem;
            margin-right: 0.5rem;
            color: #777;
        }
        svg{
            fill : #777
        }
    }
    .MuiSelect-nativeInput{
        color: #fff;
    }
}

