* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  
  @media screen and (max-width: 648px) and (orientation: portrait) {
    height: 3px;
  }
}

::-webkit-scrollbar-thumb {
  background-color: #2892f3;
  border-radius: 5px;
  
}

::-webkit-scrollbar-track {
  background: #0c77bf46;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #57a7f2;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
  
}
.scroll-id{
  position: absolute;
  top: 50px;
}
.w-100 {
  width: 100%;
}

hr {
  background: #26eb6e;
  height: 3px;
  border: none;
  margin: 30px 0;
  color: #2f0707;

}


@keyframes marquee{
  0%{
    transform: translateX(0);
  }
  // 50%{
  //   transform: rotate(-180deg);
  // }
  100%{
    transform: translateX(-50%);
  }
}

@keyframes loadingAni {
  0%{
    transform: rotate(0deg);
  }
  // 50%{
  //   transform: rotate(-180deg);
  // }
  100%{
    transform: rotate(-360deg);
  }
  
}


@keyframes slideShow {
  0%{
    transform: translateX(100%);
  }
  // 50%{
  //   transform: rotate(-180deg);
  // }
  100%{
    transform: translateX(0);
  }
  
}

@keyframes progressAnimate {
  0%{
    width: 0;
  }
  100%{
    width: var(--progress-width);
  }
}