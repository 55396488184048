/* KEYFRAMES */

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(359deg);
  }
}
.loader-div{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.885);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p{
    font-size: min(25px, 2.5vw);
    @media screen and (max-width: 576px) and (orientation:portrait){
      font-size: 18px;
    }
  }
}

  .spinner-box {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
  
  .three-quarter-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid #8e6626;
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spin .5s linear 0s infinite;
  }
  
  